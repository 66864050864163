import { AuthProvider } from '@/types';
import * as SocialIcons from '@/components/presentational/tailwind/Auth/Icons';

function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const RenderProviders = ({
  providers,
  onProviderLoginRequested,
  isLoading,
  isLogin = true,
}: {
  providers: AuthProvider[];
  onProviderLoginRequested: (provider: AuthProvider) => void;
  isLoading: boolean;
  isLogin?: boolean;
}) => {
  return (
    <div className="space-y-2">
      {providers.map((provider) => {
        const AuthIcon = SocialIcons[provider];
        return (
          <button
            className="group relative flex w-full cursor-pointer justify-center gap-3 ring-neutral-200 rounded-full ring-1 bg-white px-4 py-3.5 text-md font-medium text-gray-900 shadow transition-all duration-200 hover:bg-neutral-50 focus:border-fuchsia-400 focus:outline-none focus:ring-1 focus:ring-fuchsia-500 focus:ring-offset-2"
            disabled={isLoading}
            onClick={() => onProviderLoginRequested(provider)}
            key={provider}
          >
            {/* Set brightness to 2 on group hover */}
            <AuthIcon className="self-center" />
            {isLogin ? (
              <span className="self-center">
                Log in with {capitalize(provider)}
              </span>
            ) : (
              <span className="self-center">
                Sign up with {capitalize(provider)}
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};
