'use client';
// import { useMaintenanceMode } from '@/contexts/MaintenanceModeContext';
import { Tooltip } from 'react-tooltip';
import { classNames } from '@/lib/utils';

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & { withMaintenanceMode?: boolean; authButton?: boolean };

export function Button({
  className: classNameProp,
  disabled: disabledProp,
  withMaintenanceMode = false,
  authButton = false,
  children,
  ...props
}: ButtonProps) {
  // const isInMaintenanceMode = useMaintenanceMode() && withMaintenanceMode;
  // const disabled = isInMaintenanceMode || disabledProp;
  // const className = isInMaintenanceMode
  //   ? `${classNameProp} cursor-not-allowed `
  //   : classNameProp;

  // Remove in order to enable maintenance mode
  const isInMaintenanceMode = false;
  const disabled = disabledProp;
  const className = classNameProp;

  const buttonElement = (
    <button
      disabled={disabled}
      className={classNames(
        disabled
          ? 'cursor-not-allowed bg-yellow-300 text-black focus:ring-yellow-500'
          : 'keesy-button-bg-gradient text-shadow text-white hover:bg-[#FCA6E9] focus:border-[#7549F4] focus:ring-[#7549F4]',
        'z-[1] group border shadow-xs relative cursor-pointer overflow-hidden gap-2 rounded-[10px] border-transparent px-4 py-3.5 text-[0.95rem] font-semibold transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2',
        className
      )}
      style={props.style || { boxShadow: 'rgb(110 63 243 / 39%) 0px 0px 0px 1px, rgba(47, 10, 154, 0.4) 0px 1px 2px 0px' }}
      {...props}
    >
      <div className={`transition-all duration-300 ease-in-out button-shine-gradient group-hover:opacity-0 ${className.includes('rounded-') ? className.match(/rounded-[\w]+/)[0] : 'rounded-xl'}`}
        style={authButton ? { boxShadow: '0px 1px 2px rgba(47, 10, 154, 0.4), inset 0 0px 0 1px rgb(255 255 255 / 61%)' } : {}}
      />
      {children}
    </button>
  );
  if (isInMaintenanceMode) {
    const wrapperId = `${props.id}-wrapper`;
    return (
      <>
        <div id={wrapperId}>{buttonElement}</div>
        <Tooltip anchorId={wrapperId}>
          <p>The App is currently in maintenance mode. </p>
          <p>Please check back later.</p>
        </Tooltip>
      </>
    );
  }
  return buttonElement;
}
