import Anchor from 'shared-ui/components/Anchor';
import { PropsOf } from '@headlessui/react/dist/types';
import { useState } from 'react';
import { Button } from '../Button';

export const EmailAndPassword = ({
  onSubmit,
  view,
  isLoading,
  withMaintenanceMode,
}: {
  onSubmit: (data: { email: string; password: string }) => void;
    view: 'sign-in' | 'sign-up' | 'reset-password';
  isLoading: boolean;
} & Pick<PropsOf<typeof Button>, 'withMaintenanceMode'>) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit({ email, password });
      }}
    >
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700" htmlFor="email">
            Email address*
          </label>
          <div className="relative mt-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg className="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#B6B2BD">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </div>
            <input
              id={`${view}-email`}
              name="email"
              type="email"
              placeholder="Enter your email"
              disabled={isLoading}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete={'email'}
              autoFocus
              required
              className="shadow-xs block w-full flex-1 rounded-[11px] border-gray-300 py-2.5 px-4 pl-12 text-[1.03rem] 
              ring-1 !focus:ring-0 border-transparent border-0 focus:ring-offset-[-1px] focus:shadow-[0_0px_0px_3px_rgba(110,63,243,0.12)] focus:border-[#7549f4] focus:ring-[#7549f4] ring-neutral-200 placeholder-neutral-400"
            />
          </div>
        </div>
        <div className="pt-1 space-y-2">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password*
          </label>
          <div className="relative mt-2">
            <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
              <svg className="w-4 h-4 text-gray-400" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.66667 6.33333V4.33333C3.66667 2.49238 5.15905 1 7 1C8.61264 1 9.95781 2.14517 10.2667 3.66667M4.2 13H9.8C10.9201 13 11.4802 13 11.908 12.782C12.2843 12.5903 12.5903 12.2843 12.782 11.908C13 11.4802 13 10.9201 13 9.8V9.53333C13 8.41323 13 7.85318 12.782 7.42535C12.5903 7.04903 12.2843 6.74307 11.908 6.55132C11.4802 6.33333 10.9201 6.33333 9.8 6.33333H4.2C3.0799 6.33333 2.51984 6.33333 2.09202 6.55132C1.71569 6.74307 1.40973 7.04903 1.21799 7.42535C1 7.85318 1 8.41323 1 9.53333V9.8C1 10.9201 1 11.4802 1.21799 11.908C1.40973 12.2843 1.71569 12.5903 2.09202 12.782C2.51984 13 3.0799 13 4.2 13Z" stroke="#B6B2BD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <input
              id={`${view}-password`}
              name="password"
              type="password"
              placeholder="Enter your password"
              disabled={isLoading}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete={
                view === 'sign-in' ? 'current-password' : 'new-password'
              }
              required
              className={`block w-full rounded-[11px] border-gray-300 py-2.5 px-4 pl-12 text-[1.03rem] 
                ring-1 !focus:ring-0 border-transparent border-0 focus:ring-offset-[-1px] focus:shadow-[0_0px_0px_3px_rgba(110,63,243,0.12)] focus:border-[#7549f4] focus:ring-[#7549f4] shadow-xs ring-neutral-200 placeholder-neutral-400
              `}
            />
          </div>
        </div>

        {(view === 'sign-in' || view === 'reset-password') ? (
          <div className="flex items-center justify-between py-2">
            <div className="text-sm">
              <Anchor href="/forgot-password" className="font-medium transition-colors duration-200 ease-in-out text-zinc-500 hover:text-zinc-600">
                Forgot your password?
              </Anchor>
            </div>
          </div>
        ) : <div className='py-1' />}
        <div>
          {isLoading ? (
            <Button
              disabled
              type="submit"
              authButton={true}
              withMaintenanceMode={withMaintenanceMode}
              className="flex justify-center rounded-full w-full text-shadow px-4 py-3.5 text-md font-medium text-white bg-yellow-300 border border-transparent shadow-sm cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              style={{
                background: `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(275.25deg, #460EE7 0.81%, #7C52F5 54.05%, #460EE7 99.4%)`,
                boxShadow: `0px 1px 2px rgba(47, 10, 154, 0.4), 0px 0px 0px 1px rgba(117, 73, 244, 0.8)`,
              }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              type="submit"
                authButton={true}
                withMaintenanceMode={withMaintenanceMode}
                className="flex justify-center rounded-full w-full text-shadow px-4 py-3.5 text-md font-medium text-white border border-transparent shadow-sm focus:outline-none focus:ring-2"
                style={{
                  background: `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(275.25deg, #460EE7 0.81%, #7C52F5 54.05%, #460EE7 99.4%)`,
                  boxShadow: `0px 1px 2px rgba(47, 10, 154, 0.4), 0px 0px 0px 1px rgba(117, 73, 244, 0.8)`,
                }}
            >
              {view === 'sign-in' ? 'Login' : 'Start your free trial'}
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

